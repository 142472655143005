:root {
  --kanamaja-washed-white: #f8edd5;
  --kanamaja-red: #db463a;
  --kanamaja-green: #0e5f66;
  --kanamaja-green-dark: #384c4c;
  --kanamaja-green-light: #00908e;
}

.web {
  background-color: var(--kanamaja-green-dark);
  color: var(--kanamaja-washed-white);
  font-family: RetroTeam;
}

.header {
}

body {
  margin: 0;
  font-family: 'RetroTeam', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: var(--kanamaja-green-dark);
  color: var(--kanamaja-washed-white);
}

.footer {
  min-height: 10vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  font-size: calc(10px + 2vmin);
}

code {
  font-family: 'RetroTeam'
}
