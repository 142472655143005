.App {
  text-align: center;
  min-height: 90vh;
  display: grid;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  font-size: calc(10px + 2vmin);
}

.App-logo {
  height: 90vmin;
  pointer-events: none;
}

.App-link {
  color: #61dafb;
}

a.link {
  color: var(--kanamaja-red);
}