.LandingPage {
  text-align: center;
  min-height: 90vh;
  justify-content: space-around;
  font-size: calc(10px + 2vmin);
}

.LandingPage-logo {
  max-height: 90vmin;
  width: 100%;
  pointer-events: none;
}

.logo-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.LandingPage-link {
  color: #61dafb;
}

a.link {
  color: var(--kanamaja-red);
}