a.social {
    margin: 0 1rem;
    transition: transform 250ms;
    display: inline-block;
}

a.social:hover {
    transform: translateY(-2px);
}

a.facebook {
    color: #4968ad;
}

a.email, a.web, a.instagram {
    color: black;
    background-color: inherit;
}

a.youtube {
    color: #FF0000;
}

a.spotify {
    color: #1DB954;
}

a.soundcloud {
    color: #ff7700;
}