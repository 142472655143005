.artist-card-link {
    text-decoration: none;
}

.artist-card-content {
    background-color: var(--kanamaja-washed-white);
}

.artist-card-style {
    text-decoration: none;
    text-align: center;
    margin: 6px 0 0 0;
}